import React, { Component } from 'react';
class DropDown extends Component {
    state = {  }
    render() { 
        return (  
            <select>
  <option value="high">high</option>
  <option value="medium">medium</option>
  <option value="low">low</option>
  
</select>
        );
    }
}
 
export default DropDown;