import React, { Component } from "react";
import PerfectScrollbar from "react-custom-scrollbars";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import posed from "react-pose";

const Box = posed.div({
  visible: { x: 0, transition: { duration: 300 } },
  hidden: { x: 500 }
});

class SidebarMain extends Component {
  state = { isVisible : false};

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  render() {
    let styles = {};
    styles.sidebar = { right: 0, zIndex: "1000" };
    styles.sidebarBackground = { rgba: "(0,0,0,0)" };
    return (
      <div className="w-full h-full absolute">
        <Link to="/home">
          <div
            className="sidebarBackground h-full"
            style={styles.sidebarBackground}
          />
        </Link>
        <Box
          pose={this.state.isVisible ? "visible" : "hidden"}
          className="sidebarContent fixed bg-white max-w-sm rounded shadow-lg w-full lg:w-1/3 h-full"
          style={styles.sidebar}
        >
          <PerfectScrollbar autoHide>
            <div className="px-8 py-4">
              <Link to="/home">
                <div className="text-right pb-2 text-3xl">
                  <i className="fas fa-times" />
                </div>
              </Link>
              {this.props.children}
            </div>
          </PerfectScrollbar>
        </Box>
      </div>
    );
  }
}

export default SidebarMain;
