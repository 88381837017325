/*global chrome*/
import React, { Component } from 'react';
import Attachments from './attachments/attachments';
import TodoApp from './todoList/todoList'

import NoAttachments from './attachments/noAttachments';
import ShowWeekTestFromPage from './showWeekTextFromPage';
import database from '../../data';
import SidebarMain from './sidebarMain';
import { weekdays } from 'moment';




class SidebarSubjectWeek extends Component {
    state = { isVisible : false, data: null}

    render() {
        let weekData = this.props.data[this.props.match.params.id][this.props.match.params.branchid];
        if(weekData){
            console.log(weekData)
           let totalFiles = (weekData.files.length + weekData.assignments.length + weekData.quizzes.length + weekData.folders.length + weekData.links.length + weekData.forums.length)
            return (
                <SidebarMain>
                    <div className="text-2xl font-bold">{this.props.match.params.id}</div>
                    {/* <div className="text">{this.props.match.params.id}</div> */}
                    <div className="bg-red-light brow my-4"></div>
                    <a href={weekData.link} target="_blank" rel="noopener noreferrer" className = "text-md text-grey-darker flex no-underline flex-col md:flex-row hover:text-grey-dark">
                        <div className="flex-grow py-2">{weekData.name}</div>
                        <div className="py-2">Open Moodle <i className="fas fa-external-link-alt"></i></div>
                    </a>
                    {database.getBuildType() === "extension"?<ShowWeekTestFromPage link={weekData.link} />:false}
                    <div className="mt-4">
                        {(weekData.files && weekData.files.length) ? <Attachments attachments={weekData.files} heading={"FILES"} icon={"fas fa-file"}/>: false}
                        {(weekData.assignments && weekData.assignments.length) ? <Attachments attachments={weekData.assignments} heading={"ASSIGNMENTS"} icon={"fas fa-clipboard"}/>: false}
                        {(weekData.quizzes && weekData.quizzes.length) ? <Attachments attachments={weekData.quizzes} heading={"QUIZZES"} icon={"fas fa-question-circle"}/>: false}
                        {(weekData.folders && weekData.folders.length) ? <Attachments attachments={weekData.folders} heading={"FOLDERS"} icon={"fas fa-folder"}/>: false}
                        {(weekData.links && weekData.links.length) ? <Attachments attachments={weekData.links} heading={"LINKS"} icon={"fas fa-link"}/>: false}
                        {(weekData.forums && weekData.forums.length) ? <Attachments attachments={weekData.forums} heading={"FORUMS"} icon={"fas fa-comment"}/>: false}
                        
                        {totalFiles ? false : <NoAttachments attachments={weekData}/>}
                        {/* {totalFiles && typeof(chrome) !== 'undefined' ? <DownloadAttachments attachments={weekData} /> : false} */}
                    </div>
                </SidebarMain>
            );
        }
        else {
            return false;
        }
    }
}
 
export default SidebarSubjectWeek;