import React, { Component } from "react";
import PerfectScrollbar from "react-custom-scrollbars";
import data from "../../data";
import * as firebase from "firebase/app";
import moment from 'moment';

class Message extends Component {
  state = {};
  render() {
    return (
      <div className="mt-4 flex flex-col items-start">
        <div className="text-grey-darker inline-block text-sm">
          {this.props.name?this.props.name:"Anon"} | {moment(this.props.time.toDate()).fromNow()}
        </div>
        <p className="rounded-sm py-3 px-2 bg-blue-lighter mt-1">
          {this.props.message}
        </p>
      </div>
    );
  }
}

class ChatPage extends Component {
  state = { messages: [] };
  scrollObj = React.createRef();
  componentDidMount() {
    data.listenForChats(this.props.subject.label, this.newMessage);
  }
  newMessage = messages => {
    messages = messages.sort(function(a, b) {
      return a.time - b.time;
    });
    this.setState({ messages: messages }, () => {
      this.scrollObj.current.scrollToBottom();
    });
  };
  _handleKeyPress = event => {
    if (event.key === "Enter") {
      this.sendMessage(event.target.value);
      event.target.value = "";
    }
  };
  sendMessage = message => {
    if (message.trim()){
      data.sendMessage(
        this.props.subject.label,
        message,
        firebase.auth().currentUser.displayName,
        firebase.auth().currentUser.email
      );
    }
  };
  sendButton = () => {
    this.sendMessage(this.messageInput.value);
    this.messageInput.value = ''
  }
  componentWillUnmount() {
    data.listenForChats(this.props.subject.label, () => {});
  }
  render() {
    return (
      <div className="w-full mx-auto flex flex-grow items-center justify-center">
        <div className="bg-white text-black font-sans leading-normal w-full flex flex-col h-full">
          <header className="bg-blue-dark text-white py-4 flex items-center justify-between">
            <div
              onClick={this.props.goBack}
              className="inline-flex items-center pl-4 cursor-pointer"
            >
              <span className="uppercase font-semibold tracking-wide text-sm">
                <i className="fa fa-chevron-left" /> Back
              </span>
            </div>
            <div className="inline-flex items-center">
              <span className="uppercase font-semibold tracking-wide text-sm">
                {this.props.subject.label} Chat
              </span>
            </div>
            <div className="inline-flex items-center" />
          </header>

          <div className="container m-auto pb-4 px-3 flex-grow overflow-hidden">
            {/* <div className="relative flex items-center justify-center">
              <hr className="bg-grey-light h-px absolute pin" />
              <span className="bg-white uppercase text-grey-dark inline-block px-2 py-1 text-xs z-10 leading-none">
                Today
              </span>
            </div> */}
            <PerfectScrollbar ref={this.scrollObj} autoHide>
              {this.state.messages.map((message, y) => {
                return (
                  <Message
                    message={message.message}
                    email={message.userEmail}
                    name={message.userName}
                    time={message.time}
                    key={y}
                  />
                );
              })}
            </PerfectScrollbar>
          </div>
          <div className="container m-auto items-center">
            <hr className="m-auto bg-grey-light h-px" />
            <div className="flex pb-2">
              <input
                ref={(messageInput) => { this.messageInput = messageInput }}
                onKeyPress={this._handleKeyPress}
                className="inline-block resize-none flex-auto ml-4 py-3 outline-none leading-none h-10 text-grey-darkest"
                placeholder="Enter your message"
              />
              <button onClick={this.sendButton} className="font-bold tracking-wide uppercase text-blue px-4 py-3 hover:text-blue-light">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatPage;
