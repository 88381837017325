import React, { Component } from "react";
import SidebarMain from "../sidebarMain";
import data from "../../../data";
import moment from "moment";
class Planner extends Component {
  state = { calendarData: false };
  componentDidMount() {
    data
      .getUserPreferences("sesskey")
      .then(sesskey => {
        return this.getCalendarEvents(sesskey);
      })
      .then(res => res.json())
      .then(response => {
        if (response[0].error) {
          this.setState({ calendarData: "fetch-error" });
        } else {
          this.setState({ calendarData: response[0].data.events });
        }
      });
  }

  getCalendarEvents(sesskey) {
    return fetch(
      `https://lms.monash.edu/lib/ajax/service.php?sesskey=${sesskey}&info=core_calendar_get_action_events_by_timesort`,
      {
        credentials: "include",
        headers: {
          accept: "application/json, text/javascript, */*; q=0.01",
          "accept-language": "en-AU,en-US;q=0.9,en;q=0.8",
          "cache-control": "no-cache",
          "content-type": "application/json",
          pragma: "no-cache",
          "x-requested-with": "XMLHttpRequest"
        },
        referrer: "https://lms.monash.edu/my/",
        referrerPolicy: "no-referrer-when-downgrade",
        body: `[{"index":0,"methodname":"core_calendar_get_action_events_by_timesort",
          "args":{"limitnum":10, "timesortfrom":${moment()
            .startOf("week")
            .unix()
            .toString()}, "timesortto":${moment()
          .add(4, "M")
          .unix()
          .toString()}}}]`,
        method: "POST",
        mode: "cors"
      }
    );
  }
  refreshCalendar = () => {
    if (this.state.calendarData && this.state.calendarData !== "fetch-error") {
      return (
        <div>
          {this.state.calendarData.map(event => (
            <a
              target="_blank"
              href={event.url}
              className="block no-underline text-white bg-blue hover:bg-blue-dark text-white font-bold p-4 my-2 rounded w-full"
            >
              <div>
                <div>{event.name}</div>
                <div>{event.course.shortname}</div>
              </div>
              <div className="text-sm mt-3">
                {moment
                  .unix(event.timestart)
                  .format("dddd, MMM DD, h:mm A")
                  .toString()}
              </div>
            </a>
          ))}
        </div>
      );
    } else if (
      this.state.calendarData &&
      this.state.calendarData === "fetch-error"
    ) {
      return (
        <div className="text-center pt-5">
          Your session key has expired. Please click the refresh button above!
        </div>
      );
    } else {
      return (
        <div className="text-center pt-5">
          <i className="fas fa-circle-notch fa-spin text-5xl" />
        </div>
      );
    }
  };

  render() {
    return (
      <SidebarMain>
        <div className="text-2xl font-bold">Your Planner</div>
        <div className="bg-red-light brow my-4" />
        {this.refreshCalendar()}
      </SidebarMain>
    );
  }
}

export default Planner;
